    <script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import { PostDataMethods, notificationMethods } from "@/state/helpers";

    export default {
        components: {
            Layout,
            PageHeader,
        },
        data() {

            return {
                title: "Create User",
                items: [
                    {
                        text: "Wesal",
                    },
                ],
                Status: "",
                IsDeposit: false,
                FormData: {
                    username: "manager",
                    phone: "0988888888",
                    password: "12345678",
                    points: 0,
                    credit: 0,
                    deposit: 0,
                    role: "",

                },
                roles: ["manager", "user"],

                price: 0,
                commission: ["5%", '3%', "2%"]
            };

        },
        methods: {
            ...PostDataMethods,
            ...notificationMethods,

            submitForm() {
                this.postData({ key: "create_manager", data: this.FormData }).then(response => {
                    if (response.status == 422)
                        this.makeToast("danger ", "Fial", response.data.message);
                    else if (response.status == 500)
                        this.makeToast("danger ", "Fial", response.data.message);
                    else
                        this.makeToast("success ", "Success", response.data.message);

                })
            },

            OnInput() {
                if (this.commission.value == "5%")
                    this.price = this.FormData.points * 1.05;
                else if (this.commission.value == "3%")
                    this.price = this.FormData.points * 1.03;
                else if (this.commission.value == "2%")
                    this.price = this.FormData.points * 1.02;
                else
                    this.price = this.FormData.points;
                this.SetPrice();
            },

            SetPrice() {
                if (this.IsDeposit) {

                    this.FormData.deposit = this.price;
                    this.FormData.credit = 0;
                }
                else if (!this.IsDeposit) {
                    this.FormData.credit = this.price;
                    this.FormData.deposit = 0;
                }
            },

            makeToast(variant = null, body = null, title = null) {
                this.$bvToast.toast(title, {
                    title: `${body}`,
                    variant: variant,
                    solid: true,
                });
            },

        },
    };
</script>

    <template>
        <Layout>
            <PageHeader :title="title" :items="items" />

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="conteanir" color="#5664d2">
                                <div class="row-main" title="Basic Info">
                                    <div class="tab-pane" id="basic-info">
                                        <h4 class="card-title">User Information</h4>
                                        <p class="card-title-desc">Fill all information below</p>
                                        <!-- Start From Create User -->
                                        <form @submit.prevent="submitForm">
                                            <div class="row">
                                                <div class="form-group col-lg-2">
                                                    <label>User Name</label>
                                                    <input v-model="FormData.username" type="text" class="form-control"
                                                        placeholder="Name" />
                                                </div>
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label>Phone</label>
                                                        <input v-model="FormData.phone" type="text" placeholder="phone"
                                                            class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label for="manufacturerbrand">Password</label>
                                                        <input v-model="FormData.password" id="manufacturerbrand"
                                                            name="manufacturerbrand" type="password"
                                                            class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label class="control-label">Roles</label>
                                                        <b-form-select v-model="FormData.role" :options="roles"
                                                            size="md"></b-form-select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label>Points</label>
                                                        <input v-model="FormData.points" type="number" min="0"
                                                            @input="SetPrice" class="form-control" />
                                                    </div>
                                                </div>

                                                <div class="row" v-if="FormData.role == 'manager'">

                                                    <!--Start Commission -->
                                                    <div class="col-lg-5">
                                                        <div id="tickets-table_length" class="dataTables_length">
                                                            <label class="d-inline-flex align-items-center">
                                                                Commission
                                                            </label>
                                                            <b-form-select v-model="commission.value" @input="OnInput"
                                                                size="md" :options="commission"></b-form-select>
                                                        </div>
                                                    </div>
                                                    <!-- End Commission -->

                                                    <!--Start Price -->
                                                    <div class="col-lg-5">
                                                        <div class="form-group">
                                                            <label>Price</label>
                                                            <input :value="price" readonly class="form-control" />
                                                        </div>
                                                    </div>
                                                    <!--End Price-->

                                                    <!--Start Status -->
                                                    <div class="col-md-2">
                                                        <label>Status</label>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                v-model="IsDeposit" @change="SetPrice"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                            <label>
                                                                Paid
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- End Status -->
                                                </div>

                                            </div>
                                            <div class="row d-flex justify-content-end">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <div class="text-md-right mt-4">
                                                            <button type="submit"
                                                                class="btn btn-primary mr-2 waves-effect waves-light">
                                                                Create
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- End Form -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    </template>
